.full-width--disclaimer {

	& > .disclaimer-desktop {
		@media (max-width: 479px) {
			display: none;
		}

		p {
			padding: 0 60px;
		}
	}

	& > .disclaimer-mobile {
		@media (min-width: 480px) {
			display: none;
		}
	}

	&.disclaimer-sticky {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 9999;
		padding: 20px 0 10px;

		@media (max-width: 479px) {
			padding: 20px 50px 10px;
		}
	}
}

@media (max-width: 479px) {
  .full-width--disclaimer > .disclaimer-desktop {
    display: none;
  }
}

.full-width--disclaimer > .disclaimer-desktop p {
  padding: 0 60px;
}

@media (min-width: 480px) {
  .full-width--disclaimer > .disclaimer-mobile {
    display: none;
  }
}

.full-width--disclaimer.disclaimer-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 20px 0 10px;
}

@media (max-width: 479px) {
  .full-width--disclaimer.disclaimer-sticky {
    padding: 20px 50px 10px;
  }
}
